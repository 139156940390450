import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Bloomea`}
        description={`Bloomea - modeling skóry na twarz i ciało, dający efekt resurfacingu laserowego.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Bloomea</h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Główne zastosowania zabiegu BLOOMEA to przede wszystkim modeling
                skóry na twarz i ciało, dający efekt resurfacingu laserowego.
                Bloomea to unikalny zabieg bazujący na 3 synergicznie
                działających funkcji ukrytych pod skrótem technologii TRIO M.M.L
                <ul>
                  <li>MAKROEKSFOLIACJI</li>
                  <li>MIKROWIBRACJI</li>
                  <li>LUMINOTERAPII</li>
                </ul>
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Efekty</h2>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>redukcja zmarszczek</li>
                  <li>redukcja przebarwień</li>
                  <li>wygładzenie i napięcie skóry</li>
                  <li>przywrócenie miękkości</li>
                  <li>głębokie nawilżenie</li>
                  <li>mechaniczny resurfacing</li>
                  <li>bruzdy/ blizny potrądzikowe</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
